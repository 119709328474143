import { render, staticRenderFns } from "./ServiceTwo.vue?vue&type=template&id=1d9d5938"
import script from "./ServiceTwo.vue?vue&type=script&lang=js"
export * from "./ServiceTwo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports