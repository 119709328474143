<template>
    <Layout header-class="header-transparent" :footer-style="1">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-default height-850 bg_image"
             data-black-overlay="7"
             :style="{'background-image': `url(${require(`@/assets/images/bg/waf_bg.jpg`)})`}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-center">
                            <span class="subtitle" data-aos="fade-up" data-aos-delay="100">
                                CYBER SECURITY SERVICES
                            </span>
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">Cloud WAF Service</h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="200">
                                Providing an excellent security protection experience, making your business more convenient
                            </p>
                            <!-- <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                                <a class="btn-default btn-medium round btn-icon"
                                   href="#">
                                    Purchase Now
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </a>
                                <router-link class="btn-default btn-medium btn-border round btn-icon" to="/contact">
                                    Contact Us
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </router-link>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gapBottom pt--100">
            <div class="container">
                <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="security Services"
                            title="NetO Cloud WAF Service"
                            description="Fast networks require advanced network <br> security and application security"
                        />
                    </div>
                    <div class="inner text-left">
                        <h5 data-aos="fade-up" data-aos-delay="150">
                            NetO Cloud WAF Brief introduction:
                        </h5>
                        <p class="description" data-aos="fade-up" data-aos-delay="200">
                            The NetO Cloud WAF service leverages a machine learning-based proactive security model to deliver comprehensive protection against OWASP Top 10 threats and other vulnerabilities. 
                            It is considered the industry's foremost solution for safeguarding web applications and APIs. This service encompasses dynamic security policies, automatic false positive remediation,
                             built-in DDoS protection, API security protection, and bot mitigation, effectively thwarting data loss.
                        </p>
                    </div>
                <ServiceWAFBriefIntroduction
                    service-style="service__style--1"
                    text-align="center"
                    icon-size="39"
                />
                <!-- service-style="service__style--1 icon-circle-style" -->
                <div class="inner text-left" style="margin-top: 30px;">
                        <h5 data-aos="fade-up" data-aos-delay="150">
                            NetO Cloud WAF Summary:
                        </h5>
                        <p class="description" 
                            data-aos="fade-up" 
                            data-aos-delay="200" 
                            v-for="(Summary,index) in WAFSummary">
                                {{ Summary.id }}.{{ Summary.text }}
                        </p>
                       
                    </div>
            </div>
        </div>
        <!-- End Service Area -->

        <Separator/>

        <!-- Start Portfolio Area  -->
        <!-- <div class="rwt-portfolio-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Business Projects"
                            title="Business Case Studies.!"
                            description="We provide company and finance service for <br /> startups and company business."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Portfolio column="col-lg-4 col-md-6 col-12 mt--30" :portfolio-data="portfolioData"/>
            </div>
        </div> -->
        <!-- End Portfolio Area  -->

        <!-- <Separator/> -->

        <!-- Start Timeline Area  -->
        <!-- <div class="rwt-timeline-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Working Process"
                            title="Business Working Process."
                            description="We provide company and finance service for <br /> startups and company business."
                        />
                    </div>
                </div>
                <div class="row mt_dec--20">
                    <div class="col-lg-12">
                        <Timeline :timeline-data="timelineData"/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Timeline Area -->

        <!-- Start Video Area -->
        <!-- <div class="rwt-video-area rn-section-gapBottom">
            <div class="container">
                <Video :video-data="videoData" :video-style="2" column="col-lg-12" data-aos="fade-up"/>
            </div>
        </div> -->
        <!-- End Video Area -->

        <!-- <Separator/> -->

        <!-- Start Elements Area -->
        <!-- <div class="rwt-testimonial-area rn-section-gap">
            <div class="container">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Client Feedback"
                            title="Our Clients Feedback."
                            description="We provide company and finance service for <br /> startups and company business."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Testimonial :testimonial="testimonialData5" :testimonial-style="5" data-aos="fade-up"/>
            </div>
        </div> -->
        <!-- End Elements Area -->

        <!-- <Separator/> -->

        <!-- Start Blog Area -->
        <!-- <div class="blog-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Latest News"
                            title="Our Latest News."
                            description="We provide company and finance service for <br /> startups and company business."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="100 + index"
                         v-for="(blog, index) in posts"
                         :key="index"
                         v-if="index < 3">
                        <BlogPost :blog="blog"/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Start End Area -->

        <!-- Start Elements Area -->
        <!-- <div class="rwt-callto-action-area rn-section-gapBottom">
            <div class="wrapper">
                <CallToAction
                    :style-type="5"
                    title="Ready to start creating a standard website?"
                    subtitle="Finest choice for your home & office"
                    btn-text="Purchase Doob"
                />
            </div>
        </div> -->
        <!-- End Elements Area -->
    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import Icon from '../../components/icon/Icon'
    import Separator from '../../components/elements/separator/Separator'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import ServiceOne from '../../components/elements/service/ServiceOne'
    import Portfolio from '../../components/elements/portfolio/Portfolio'
    import Timeline from '../../components/elements/timeline/Timeline'
    import Video from '../../components/elements/video/Video'
    import Testimonial from '../../components/elements/testimonial/Testimonial'
    import BlogPostMixin from '../../mixins/BlogPostMixin'
    import BlogPost from '../../components/blog/BlogPost'
    import CallToAction from '../../components/elements/callToAction/CallToAction'
    //
    import ServiceWAFBriefIntroduction from '../../components/elements/service/ServiceWAF-BriefIntroduction.vue'


    export default {
        name: 'BusinessConsulting',
        components: {
            ServiceWAFBriefIntroduction,
            ServiceOne,
            CallToAction,
            BlogPost,
            Testimonial,
            Video,
            Timeline,
            Portfolio,
            SectionTitle,
            Separator,
            Icon,
            Layout
        },
        mixins: [BlogPostMixin],
        data() {
            return {
                timelineData: [
                    {
                        id: '1',
                        date: 'Step-1',
                        title: 'Your working process you can present.',
                        description: 'Lorem ipsum dolor sit amet at — consectetur adipisicing elit. Hic, expedita. Incidunt laboriosam.',
                        image: 'timeline-01',
                        workingStep: [
                            {
                                stepTitle: 'Gain your works',
                                stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing',
                            },
                            {
                                stepTitle: 'Working process',
                                stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing',
                            },
                        ]
                    },
                    {
                        id: '2',
                        date: 'Step-2',
                        title: 'Our compnay working process to present.',
                        description: 'Lorem ipsum dolor sit amet at — consectetur adipisicing elit. Hic, expedita. Incidunt laboriosam.',
                        image: 'timeline-02',
                        workingStep: [
                            {
                                stepTitle: 'Follow this process',
                                stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing',
                            },
                            {
                                stepTitle: 'Working experience',
                                stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing',
                            },
                        ]
                    },
                ],
                videoData: [
                    {
                        thumb: require(`@/assets/images/bg/bg-image-4.jpg`),
                        src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk',
                        title: `Watch the creative process behind <br/> the digital marketing.`
                    }
                ],
                testimonialData5: [
                    {
                        form: 'Poland',
                        description: 'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
                        name: 'John Due',
                        subtitle: 'App Developer',
                        image: 'testimonial-dark-01'
                    },
                    {
                        form: 'Germany',
                        description: 'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
                        name: 'John Due',
                        subtitle: 'App Developer',
                        image: 'testimonial-dark-02'
                    },
                    {
                        form: 'USA',
                        description: 'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
                        name: 'Janen',
                        subtitle: 'App Developer',
                        image: 'testimonial-dark-03'
                    },
                ],
                portfolioData:  [
                    {
                        id: 1,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        title: 'App Development',
                        subtitle: 'Branded client',
                        date: '30 May 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 2,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        title: 'Business Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 3,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        title: 'Photoshop Design',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'art',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 4,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        title: 'Native Application',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-04.jpg`)
                        }
                    },
                    {
                        id: 5,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        title: 'Vue Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 6,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        title: 'App Installment',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-06.jpg`)
                        }
                    }
                ],
                WAFSummary: [
                    {
                        id: 1,
                        text: "Comprehensive and accurate web security coverage: Provides protection against OWASP's top 10 categories of web threats and other attacks, including injection, XSS, CSRF, invalid authentication, sensitive information leakage, and session management. Attacks and threats outside of the OWASP Top 10 list can be covered."
                    },{
                        id: 2,
                        text: "Automatic protection against zero-day web attacks: Combines passive and active security models to achieve security coverage with minimal impact on legitimate traffic and avoid false positives and false negatives."
                    },{
                        id: 3,
                        text: "API Security: Provides a flexible, scalable web application security solution that protects APIs from known and zero-day attacks."
                    },{
                        id: 4,
                        text: "Encrypted Web Attack Traffic Protection: Detects and blocks various web attack traffic encapsulated in the HTTPS protocol, and provides both certificated and certificateless mitigation methods."
                    },{
                        id: 5,
                        text: "Defend against bot attacks: Address threats posed by advanced bots, such as web scraping, web application DDoS, password cracking attacks, and clickjacking, through device fingerprinting and activity tracking."
                    },{
                        id: 6,
                        text: "Authentication gateway: provides user authentication and single sign-on functions, authorizes and executes web access control policies, and supports multiple authentication methods."
                    },{
                        id: 7,
                        text: "Compliance: Support enterprises to fully comply with the requirements of PCI DSS Section 6.6, provide security graphical reports and PCI compliance reports, analyze security policies and achieve compliance action planning."
                    },{
                        id: 8,
                        text: "Prevent information leakage: It has a data leakage prevention mechanism that can automatically mask sensitive user data, such as personally identifiable information (PII)."
                    },{
                        id: 9,
                        text: "Data encryption: Encrypts data in transit and at rest, and provides encryption key management."
                    },

                ]

            }
        }
    }
</script>