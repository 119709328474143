<template>
    <div class="row row--15 service-wrapper">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12"
             v-for="(service, index) in serviceList"
             :key=index>
            <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                 data-aos="fade-up"
                 data-aos-delay="70">
                <div class="icon">
                    <!-- <Icon :name="service.icon" :class="`size-${iconSize}`"/> -->
                    <img :src="service.img" alt=""/>
                </div>
                <div class="content">
                    <h4 class="title w-600">
                        <!-- <router-link to="/service-details" v-html="service.title"/> -->
                        {{ service.title }}
                    </h4>
                    <p class="description b1 color-gray mb--0" v-html="service.description"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'

    export default {
        name: 'ServiceOne',
        components: {Icon},
        props: {
            serviceStyle: {
                type: String
            },
            iconSize: {
                type: String|Number,
                default: 62
            },
            textAlign: {
                type: String,
                default: 'left'
            }
        },
        data() {
            return {
                serviceList: [
                    {
                        icon: 'search',
                        img: require(`@/assets/images/service/WAF_brief_1.jpg`),
                        title: 'Comprehensive web application security',
                        description: 'Achieve best-in-class web application security with a proactive security model based on machine learning to provide comprehensive protection against OWASP Top 10 threats and other vulnerabilities.'
                    },
                    {
                        icon: 'cast',
                        img: require(`@/assets/images/service/WAF_brief_2.jpg`),
                        title: 'Improve response to security threats',
                        description: 'Advanced automation technology enables instant detection and response to attacks, reducing organizational risk.'
                    },
                    {
                        icon: 'map',
                        img: require(`@/assets/images/service/WAF_brief_3.jpg`),
                        title: 'Reduce expenses',
                        description: 'Automated WAF protection and expert services reduce the burden on IT teams, enabling them to reduce costs while strengthening security.'
                    },
                    {
                        icon: 'search',
                        img: require(`@/assets/images/service/WAF_brief_4.jpg`),
                        title: 'Single pane of glass',
                        description: 'Provides an integrated security solution that provides comprehensive web application protection through centralized management and reporting.'
                    },
                ]
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        }
    }
</script>