<template>
    <Layout>
        <!-- Start Contact Area  -->
        <div class="rwt-contact-area rn-section-gap">

            <div class="container">
                <h1>NetO Privacy Policy</h1>
                <div class="text">

                    <h5>Overview of Privacy Policy</h5>
                    <el-divider></el-divider>
                    <ul>
                        <li style=" list-style-type: none;">We understand the importance you place on personal privacy,
                            which is why we have developed
                            this privacy
                            policy to help you understand our practices regarding the collection, use, sharing, and
                            protection of
                            your
                            personal information. This privacy policy applies to our website and the personal
                            information collected,
                            used, and shared through our website.</li>
                    </ul>

                    <h5>Collection of Personal Information</h5>
                    <el-divider></el-divider>
                    <ul>
                        <li style=" list-style-type: none;">We may collect the following personal information:</li>
                        <br>
                        <li>User's name and nickname</li>
                        <li>Email address</li>
                        <li>Contact phone number</li>
                        <li>Date of birth and age</li>
                        <li>Address information</li>
                        <li>User browsing habits and preferences, such as browsing and search history</li>
                        <li>Other information that may be relevant to our services</li>
                    </ul>

                    <h5>Use and Purpose of Personal Information</h5>
                    <el-divider></el-divider>
                    <ul>
                        <li style=" list-style-type: none;">The personal information we collect will be used for the
                            following purposes:</li>
                        <br>

                        <li>Provide and improve our services, such as personalized recommendations and customized
                            content</li>
                        <li>Contact you and provide the services you request or answer your questions</li>
                        <li>Analyze user behavior to improve our services and develop a better user experience</li>
                        <li>Comply with applicable laws and regulations</li>
                    </ul>

                    <h5>Sharing and Transfer of Personal Information</h5>
                    <el-divider></el-divider>
                    <ul>
                        <li style=" list-style-type: none;">We will not sell or rent your personal information to third
                            parties unless we obtain your
                            explicit
                            consent
                            or as required by law. We may share your personal information with the following entities:
                        </li>
                        <br>

                        <li>Service providers, such as payment platforms and logistics companies, to provide better
                            services</li>
                        <li>Government departments or legal agencies to comply with laws and regulations</li>
                        <li>Potential third-party buyers or investors in consideration of mergers, sales, or other
                            transactions</li>
                    </ul>

                    <h5>Protection and Security Measures for Personal Information</h5>
                    <el-divider></el-divider>
                    <ul>
                        <li style=" list-style-type: none;">We will take reasonable technical and managerial measures to
                            protect your personal
                            information. These
                            measures include:</li>
                        <br>

                        <li>Using encryption technology to protect data during transmission</li>
                        <li>Conducting background checks and training for employees who have access to sensitive data
                        </li>
                        <li>Regular security checks and maintenance of servers</li>
                        <li>When processing personal information, we will comply with applicable data protection laws
                            and
                            regulations
                        </li>
                    </ul>

                    <h5>User Rights and Choices</h5>
                    <el-divider></el-divider>
                    <ul>
                        <li style=" list-style-type: none;">You have the right to request the correction or deletion of
                            your personal information or
                            choose not to
                            receive our marketing information. You can exercise these rights through the following
                            methods:</li>
                        <br>

                        <li>Submitting an application through our website</li>
                        <li>Contacting us through our customer service email</li>
                        <li>If you believe that our processing does not comply with this privacy policy or relevant
                            regulations, you
                            can
                            file a complaint through the above methods. We will respond to your complaint within 5
                            working days. If
                            we
                            deem it necessary to further investigate or need more information to fulfill your request,
                            we will
                            contact
                            you and respond within a reasonable time.</li>
                    </ul>
                </div>
            </div>

        </div>
    </Layout>
</template>

<script>
import Layout from '../components/common/Layout'

export default {
    name: 'PrivacyPolicy',
    components: { Layout }
}
</script>

<style lang="scss" scoped>
.container {
    width: 65%;
    margin: 0 auto;
    height: 100%;
    margin-bottom: 100px;
    // color: white;
}

.text {
    display: flex;
    flex-direction: column;
    //    justify-content: center;
    // align-items: center;
    height: 100%;


}

h1 {
    // font-size: x-large;
    margin-top: 30px;
    margin-bottom: 100px;
    text-align: center;
}

p {
    font-size: large;
    line-height: 1.5;
    // margin-bottom: 20px;
}

ul {
    margin-bottom: 80px;
}

li {
    // list-style-type: none;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
    margin-top: -2px // text-indent: 2em;
}
</style>