<template>
    <Layout :header-transparency="true" :show-top-header="false">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
                    <div class="height-950 bg-overlay bg_image"
                         :style="{'background-image': `url(${require(`@/assets/images/bg/${slider.image}.jpg`)})`}">
                        <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="col-lg-12">
                                    <div class="inner text-center">
                                        <h1 class="title" v-html="slider.title"/>
                                        <p class="description" v-html="slider.description"/>
                                        <!-- <div class="button-group mt--30">
                                            <a class="btn-default btn-large round"
                                               href="#">
                                                Get started now
                                            </a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <!-- End Slider Area -->

        <Separator/>

        <div class="rwt-elements-area rn-section-gap">
            <div class="container-fluid plr--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle=""
                            title=""
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <!-- <Portfolio :portfolio-data="portfolioData" /> -->
                <!-- <Portfolio :portfolio-data="portfolioData" column="col-lg-4 col-md-6 mt--30 box-grid-layout no-overlay"/> -->
                <IDC_CDN_picture :portfolio-data="portfolioData"/>
                <!-- <img style="width: 100%;height: 100%;" src='@/assets/images/portfolio/Infrastructure-1.png' alt="Portfolio-01"/> -->
                <IDC_CDN_picture_oneRow :portfolio-data="portfolioData_2" column="col-lg-12 col-md-6 mt--30 box-grid-layout no-overlay"/>
            </div>
        </div>
        
        <Separator/>
    </Layout>
</template>

<script>
    import Layout from "../../components/common/Layout";
    import Separator from "../../components/elements/separator/Separator";
    import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
    import BlogPost from "../../components/blog/BlogPost";
    import BlogPostMixin from "../../mixins/BlogPostMixin";
    import VueSlickCarousel from "vue-slick-carousel";
    import Portfolio from "../../components/elements/portfolio/Portfolio";
    import Progressbar from "../../components/elements/progressbar/Progressbar";
    import Timeline from "../../components/elements/timeline/Timeline";
    import ServiceFour from "../../components/elements/service/ServiceFour";
    //IDC_CDN_PRTFILE_PICTURE
    import IDC_CDN_picture from "../../components/elements/portfolio/IDC_CDN_picture.vue";
    import IDC_CDN_picture_oneRow from "../../components/elements/portfolio/IDC_CDN_picture_oneRow.vue";

    export default {
        name: 'Startup',
        components: {
            IDC_CDN_picture_oneRow,
            IDC_CDN_picture,
            ServiceFour,
            Timeline, Progressbar, Portfolio, BlogPost, SectionTitle, Separator, Layout, VueSlickCarousel},
        mixins: [BlogPostMixin],
        data() {
            return {
                sliderSetting: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 993,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 481,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                },
                sliderData: [
                    {
                        image: 'index',
                        title: `IDC - CDN.`,
                        description: `A closer and faster internet for users and APPs`
                    },
                    {
                        image: 'fiber',
                        title: `Global Internet Links.`,
                        description: ``
                    },
                    {
                        image: 'planet',
                        title: `NetO global  sites deploy closer to users from new markets.`,
                        description: ``
                    }
                ],
                portfolioData_2:  [
                    {
                        id: 1,
                        portfolioImage: require(`@/assets/images/portfolio/Sites right now.png`),
                        title: ' Former Globalization Internet Network Sites',
                        category: 'Core sites have been concentrated on North America、 Europe、 India 、Japan、Korea、 Singpore and HongKong, but still far from most of the Internet users.',
                        description: 'NetO global sites deploy closer to users from new markets.'
                    },
                    {
                        id: 2,
                        portfolioImage: require(`@/assets/images/portfolio/Sites in the future.png`),
                    },
                ],
                portfolioData:  [
                    {
                        id: 1,
                        portfolioImage: require(`@/assets/images/portfolio/Infrastructure-1.png`),
                        // image: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        title: 'NetO Global Network Infrastructure',
                        // subtitle: 'Branded client',
                        // date: '30 May 2021',
                        // client: 'Rainbow Themes',
                        category: 'NetO Global Network Infrastructure',
                        // awards: ['2020 Design beautiful apps Finale Awards'],
                        // excerpt: 'Design beautiful apps.',
                        // body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        // btn: {
                        //     buttonText: 'View Projects',
                        //     link: '/'
                        // },
                        // largeImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        // subContent: {
                        //     title: 'Digital Marketing.',
                        //     subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        // },
                        // subContent2: {
                        //     title: 'App Development.',
                        //     subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        // },
                        // subContent3: {
                        //     title: 'Solution.',
                        //     subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        // },
                        // gallery: {
                        //     imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        //     imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        //     imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        // }
                    },
                    {
                        id: 2,
                        portfolioImage: require(`@/assets/images/portfolio/Infrastructure-2.png`),
                        title: 'NetO Global Network Infrastructure',
                        category: 'NetO Global Network Infrastructure',
                    },
                    {
                        id: 3,
                        portfolioImage: require(`@/assets/images/portfolio/Population.png`),
                        title: 'Population',
                        category: 'Variation Trend of Global Population and GDP',
                    },
                    {
                        id: 4,
                        portfolioImage: require(`@/assets/images/portfolio/GDP.png`),
                        title: 'GDP',
                        category: 'Variation Trend of Global Population and GDP',
                    }
                    // {
                    //     id: 2,
                    //     portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                    //     image: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                    //     title: 'Business Development',
                    //     subtitle: 'Awesome portfolio',
                    //     date: '01 March 2021',
                    //     client: 'Rainbow Themes',
                    //     category: 'design',
                    //     awards: ['2020 Design beautiful apps Finale Awards'],
                    //     excerpt: 'Design beautiful apps.',
                    //     body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                    //     btn: {
                    //         buttonText: 'View Projects',
                    //         link: '/'
                    //     },
                    //     largeImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                    //     subContent: {
                    //         title: 'Digital Marketing.',
                    //         subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     subContent2: {
                    //         title: 'App Development.',
                    //         subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     subContent3: {
                    //         title: 'Solution.',
                    //         subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     gallery: {
                    //         imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                    //         imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                    //         imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                    //     }
                    // },
                    // {
                    //     id: 3,
                    //     portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                    //     image: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                    //     title: 'Photoshop Design',
                    //     subtitle: 'Awesome portfolio',
                    //     date: '01 March 2021',
                    //     client: 'Rainbow Themes',
                    //     category: 'art',
                    //     awards: ['2020 Design beautiful apps Finale Awards'],
                    //     excerpt: 'Design beautiful apps.',
                    //     body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                    //     btn: {
                    //         buttonText: 'View Projects',
                    //         link: '/'
                    //     },
                    //     largeImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                    //     subContent: {
                    //         title: 'Digital Marketing.',
                    //         subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     subContent2: {
                    //         title: 'App Development.',
                    //         subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     subContent3: {
                    //         title: 'Solution.',
                    //         subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     gallery: {
                    //         imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                    //         imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                    //         imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                    //     }
                    // },
                    // {
                    //     id: 4,
                    //     portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                    //     image: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                    //     title: 'Native Application',
                    //     subtitle: 'Awesome portfolio',
                    //     date: '01 March 2021',
                    //     client: 'Rainbow Themes',
                    //     category: 'development',
                    //     awards: ['2020 Design beautiful apps Finale Awards'],
                    //     excerpt: 'Design beautiful apps.',
                    //     body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                    //     btn: {
                    //         buttonText: 'View Projects',
                    //         link: '/'
                    //     },
                    //     largeImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                    //     subContent: {
                    //         title: 'Digital Marketing.',
                    //         subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     subContent2: {
                    //         title: 'App Development.',
                    //         subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     subContent3: {
                    //         title: 'Solution.',
                    //         subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     gallery: {
                    //         imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                    //         imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                    //         imageThree: require(`@/assets/images/portfolio/portfolio-04.jpg`)
                    //     }
                    // },
                    // {
                    //     id: 5,
                    //     portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                    //     image: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                    //     title: 'Vue.js Development',
                    //     subtitle: 'Awesome portfolio',
                    //     date: '01 March 2021',
                    //     client: 'Rainbow Themes',
                    //     category: 'design',
                    //     awards: ['2020 Design beautiful apps Finale Awards'],
                    //     excerpt: 'Design beautiful apps.',
                    //     body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                    //     btn: {
                    //         buttonText: 'View Projects',
                    //         link: '/'
                    //     },
                    //     largeImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                    //     subContent: {
                    //         title: 'Digital Marketing.',
                    //         subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     subContent2: {
                    //         title: 'App Development.',
                    //         subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     subContent3: {
                    //         title: 'Solution.',
                    //         subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     gallery: {
                    //         imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                    //         imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                    //         imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                    //     }
                    // },
                    // {
                    //     id: 6,
                    //     portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                    //     image: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                    //     title: 'App Installment',
                    //     subtitle: 'Awesome portfolio',
                    //     date: '01 March 2021',
                    //     client: 'Rainbow Themes',
                    //     category: 'design',
                    //     awards: ['2020 Design beautiful apps Finale Awards'],
                    //     excerpt: 'Design beautiful apps.',
                    //     body: `<p>In a typical Vue.js application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                    //     btn: {
                    //         buttonText: 'View Projects',
                    //         link: '/'
                    //     },
                    //     largeImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                    //     subContent: {
                    //         title: 'Digital Marketing.',
                    //         subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     subContent2: {
                    //         title: 'App Development.',
                    //         subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     subContent3: {
                    //         title: 'Solution.',
                    //         subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    //     },
                    //     gallery: {
                    //         imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                    //         imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                    //         imageThree: require(`@/assets/images/portfolio/portfolio-06.jpg`)
                    //     }
                    // }
                ],
                progressData: [
                    {
                        id: 1,
                        title: 'Design',
                        percentage: 85,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 2,
                        title: 'Photoshop',
                        percentage: 60,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 3,
                        title: "Application",
                        percentage: 70,
                        subtitle: "Presentation your skill",
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 4,
                        title: 'Development',
                        percentage: 95,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                ],
                timelineData: [
                    {
                        id: '1',
                        title: 'Knowledge',
                        description: 'Present all available features in Essentials.',
                    },
                    {
                        id: '2',
                        title: 'Working',
                        description: 'All Feature available features in Essentials.',
                    },
                    {
                        id: '3',
                        title: 'Solution',
                        description: 'Popular Feature available features in Essentials.',
                    },
                    {
                        id: '4',
                        title: 'Process',
                        description: 'Latest Feature available features in Essentials.',
                    },
                ]
            }
        }
    }
</script>