<!-- <template>
    <form class="contact-form-1" 
    action="https://formspree.io/f/xdorbdkz"
    method="POST" 
    >
        <div class="form-group">
            <input
                type="text"
                name="fullname"
                placeholder="Your Name"
                required
                v-model="FormData.fullname"
            />
        </div>

        <div class="form-group">
            <input
                type="email"
                name="email"
                placeholder="Email Address"
                required
                v-model="FormData.email"
            />
        </div>

        <div class="form-group">
            <input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                required
                v-model="FormData.phone"
            />
        </div>

        <div class="form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
                    required
                    v-model="FormData.message"
                    >
                </textarea>
        </div>

        <div class="form-group">
            <button class="btn-default btn-large" style="width: 100%;">SendEmail Now</button>
        </div>

        <div class="form-group" v-html="showResult ? result : null"/>
    </form>
</template>

<script>
    // import axios from 'axios';

    export default {
        name: 'ContactForm',
        data() {
            return {
                showResult: false,
                result: `<p class="success-message">Your Message has been successfully sent. I will contact you soon.</p>`,
                FormData: {
                    fullname: '',
                    email: '',
                    phone: '',
                    message: ''
                },
            }
        },
        methods: {
            // sendEmail() {
            //     this.showResult = true
            // },
            // submit() {
            //     alert(1);
            //     axios.post("https://formspree.io/f/xdorbdkz", data)
            //             .then(response => {
            //                 if (response.status === 200) {
            //                 alert('successed')
            //                     // this.centerDialogVisible = true;
            //                     // this.callbackMessage = "Thank you for your submission";
            //                     // this.resetForm(formName);
            //                 }
            //             })
            //             .catch(error => {
            //                 alert('Oops! There was a problem submitting your form.' + error)
            //                 // this.centerDialogVisible = true;
            //                 // this.callbackMessage = "Oops! Something wrong has happened (" + error + ")";
            //             }).finally(() => {
            //                 alert('finally')
            //                 //1.设置submitStatus=false 
            //                 // this.submitStatus = true;
            //                 // 2.倒计时三秒钟，计时结束后将submitStatus=false
            //                 // setTimeout(() => {
            //                 //     this.submitStatus = false;
            //                 // }, 5000);
            //             });
            // }
        }
    }
</script> -->
<template>
    <form class="contact-form-1" @submit.prevent="sendEmail">
        <div class="form-group">
            <input
                type="text"
                name="fullname"
                placeholder="Your Name"
                required
                v-model="FormData.fullname"
            />
        </div>

        <div class="form-group">
            <input
                type="email"
                name="email"
                placeholder="Email Address"
                required
                v-model="FormData.email"
            />
        </div>

        <div class="form-group">
            <input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                required
                v-model="FormData.phone"
            />
        </div>

        <div class="form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
                    required
                    v-model="FormData.message"
                ></textarea>
        </div>

        <div class="form-group">
            <button class="btn-default btn-large" style="width: 100%;">SendEmail Now</button>
        </div>

        <div class="form-group" v-html="showResult ? result : null"></div>
    </form>
</template>

<script>
export default {
    name: 'ContactForm',
    data() {
        return {
            showResult: false,
            result: `<p class="success-message">Your Message has been successfully sent. We will contact you soon.</p>`,
            FormData: {
                fullname: '',
                email: '',
                phone: '',
                message: ''
            },
        };
    },
    methods: {
        sendEmail() {
            const recipient = "jason.wan@neto-x.com"; // 收件人邮箱
            const subject = encodeURIComponent(`Message from ${this.FormData.fullname}`); // 邮件主题
            const body = encodeURIComponent(`Name: ${this.FormData.fullname}\nEmail: ${this.FormData.email}\nPhone: ${this.FormData.phone}\n\nMessage:\n${this.FormData.message}`); // 邮件正文

            // 使用 mailto 协议打开默认邮件客户端
            window.location.href = `mailto:${recipient}?subject=${subject}&body=${body}`;
        }
    }
}
</script>
