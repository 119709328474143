<template>
    <div class="row row--15 service-wrapper">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12"
             v-for="(service, index) in serviceList"
             :key=index>
            <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                 data-aos="fade-up"
                 data-aos-delay="70">
                <div class="icon">
                    <!-- <Icon :name="service.icon" :class="`size-${iconSize}`"/> -->
                    <img :src="service.img" alt="" style="width: 64px;background-color: white;"/>
                </div>
                <div class="content">
                    <h4 class="title w-600">
                        <!-- <router-link to="/service-details" v-html="service.title"/> -->
                        {{ service.title }}
                    </h4>
                    <p class="description b1 color-gray mb--0" v-html="service.description"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'

    export default {
        name: 'ServiceOne',
        components: {Icon},
        props: {
            serviceStyle: {
                type: String
            },
            iconSize: {
                type: String|Number,
                default: 62
            },
            textAlign: {
                type: String,
                default: 'left'
            }
        },
        data() {
            return {
                serviceList: [
                    {
                        icon: 'search',
                        img: require(`@/assets/images/service/DDoS_brief_1.png`),
                        title: 'IoT botnet threats:',
                        description: 'With the increase in IoT devices, botnets have become one of the biggest threats facing enterprises, such as the Mirai botnet, which leverages default credentials to create powerful botnets that have triggered the largest DDoS attacks in history.'
                    },
                    {
                        icon: 'cast',
                        img: require(`@/assets/images/service/DDoS_brief_2.png`),
                        title: 'Pulse attacks and APDoS attacks:',
                        description: 'Pulse attacks are short-lived, high-volume attacks that erupt at random intervals and can last for weeks, impacting network SLAs and legitimate user access. An APDoS attack consists of multiple vectors targeting the network layer at the same time.'
                    },
                    {
                        icon: 'map',
                        img: require(`@/assets/images/service/DDoS_brief_3.png`),
                        title: 'L7 application-layer attacks:',
                        description: 'include resource-exhausting DoS attacks targeting HTTP, HTTPS, DNS, SMTP, FTP, and VOIP, as well as DoS attacks that exploit application protocol vulnerabilities, such as flood attacks and low-speed and slow-speed attacks.'
                    },
                    {
                        icon: 'search',
                        img: require(`@/assets/images/service/DDoS_brief_4.png`),
                        title: 'Ransom DDoS attack (RDoS):',
                        description: 'An attacker threatens an enterprise with a high-volume DDoS attack that requires a ransom to be paid before the deadline or the business will be unusable, threatening, and does not need to penetrate the target network or application.'
                    },
                ]
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        }
    }
</script>