<template>
    <Layout :header-transparency="true" :topbar-style="1" :footer-style="1" buy-button-class="btn-default btn-small">
        <!-- Start Slider area  -->
        <div class="slider-area slider-style-2 height-950 bg_image" data-black-overlay="10"
            :style="{ 'background-image': `url(${require(`@/assets/images/bg/index.jpg`)})` }">
            <video class="video-background" autoplay muted style="position: absolute;
                top: 0;
                left: 0;
                width: 100%; 
                height: 100%; 
                object-fit: fill;
                opacity: .8;
                 ">
                <source src="../../assets/images/bg/index-earthNetwork_1.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 main_content" style="width: 75%;margin: 0px auto;">
                        <div class="inner text-center">
                            <!-- <h4 class="subtitle" data-aos="fade-up" data-aos-delay="100">
                                <span class="theme-gradient">FOCUSE ON</span>
                            </h4> -->
                            <h1 id="title_logo" class="title display-one" data-aos="fade-up" data-aos-delay="200">NetO
                                <button @click="toggleContent" class="toggle-btn">
                {{ isContentVisible ? 'Close' : 'Learn More' }}
            </button>
                            </h1>
                        </div>
                        <div class="inner text-left">
                            <ul v-if="isContentVisible" class="list-icon" data-aos="fade-right" data-aos-offset="300"
                                data-aos-easing="ease-in-sine" data-aos-delay="400">
                                <li>
                                    <!-- <Icon name="check" size="20" icon-class="icon"/> -->
                                    <!-- for better internet connections and cyber security -->
                                    <p>
                                        Extending technology
                                    </p>
                                    <p>
                                        Optical and radio wave transmission have been around for decades, with
                                        relatively
                                        mature technology and high efficiency. It is unlikely that they will be
                                        completely
                                        replaced by optical transmission in the near future. However, the construction
                                        costs
                                        of terrestrial fiber systems are relatively high, making them suitable for
                                        densely
                                        populated areas. For remote regions and areas where fiber cannot reach,
                                        satellite
                                        transmission is being used to solve these problems. SpaceX and Starlink use
                                        satellites and laser transmission to significantly improve transmission
                                        efficiency
                                        and expand coverage from land, sea, to sky. Fiber optic transmission services
                                        trans
                                        continents, seas, and regions are affected by many uncontrollable factors. For
                                        example, there are numerous land cable connection points which are prone to
                                        breaks,
                                        and the cost of constructing, maintaining, and repairing submarine cables is
                                        very
                                        high, with significant difficulty in repairs.
                                    </p>

                                    <p>
                                        NetO is seeking a new solution to achieve more efficient internet
                                        interconnection
                                        and promote internet development in underdeveloped regions. The first step
                                        involves
                                        using satellite and laser communication technologies to establish a satellite
                                        and
                                        laser transmission system between global data centers, enabling point-to-point
                                        interconnection as a supplement and backup to terrestrial fiber transmission,
                                        thus
                                        avoiding large-scale disruptions that can cause inefficient or ineffective
                                        internet
                                        access. The second step is to implement alternative ground transmission for
                                        regions
                                        with high internet access latency, improving transmission efficiency and
                                        encouraging
                                        local operators to improve their local network infrastructure.
                                        Technologically advanced companies like SpaceX and Starlink have already turned
                                        many
                                        impossibilities into realities.
                                    </p>
                                    <p>
                                        We hope that more people can access the internet faster.

                                    </p>

                                </li>
                            </ul>

                        </div>
                        <div class="inner text-right">
                            <div class="button-group mt--40 mt_sm--20" style="display: flex;justify-content: flex-end;"
                                data-aos="fade-up" data-aos-delay="600">
                                <a class="btn-default btn-icon" href="/about">
                                    About
                                    <Icon name="arrow-right" size="20" icon-class="icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider area  -->

        <!-- Split -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="What We Do" title="What We Do" description=""
                            data-aos="fade-up" />
                    </div>
                </div>
                <Split :split-data="splitData" style="background-color: #0f0f11;" />
            </div>
        </div>
        <!-- Split -->

        <!-- Start Service Area  -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="What we can do for you" title="Service Preview."
                            description="Services providing for you." data-aos="fade-up" />
                    </div>
                </div>
                <ServiceTwo card-style="card-style-1" text-align="center" />
            </div>
        </div>
        <!-- End Service Area  -->

        <Separator />

        <!-- Start Elements Area  -->
        <div class="rwt-team-area rn-section-gap">
            <div class="wrapper plr--65 plr_md--15 plr_sm--15">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="Our Experts" title="Our Experts Team"
                            data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" v-for="(teamMember, index) in teamData"
                        :key="index">
                        <Team :team-member="teamMember" :team-style="1" />
                    </div>
                </div>
            </div>
        </div>
        <!-- End Elements Area  -->
        <Separator />
    </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Icon from '../../components/icon/Icon'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import ServiceThree from '../../components/elements/service/ServiceThree'
import Separator from '../../components/elements/separator/Separator'
import Counter from '../../components/elements/counterUp/Counter'
import Team from '../../components/elements/team/Team.vue'
import Pricing from '../../components/elements/pricing/Pricing'
import CallToAction from '../../components/elements/callToAction/CallToAction'
import AboutTwo from "../../components/elements/about/AboutTwo";
import ServiceTwo from "../../components/elements/service/ServiceTwo.vue";
import Split from '../../components/elements/split/Split'

export default {
    name: 'Corporate',
    components: {
        Split,
        ServiceTwo,
        AboutTwo, CallToAction, Pricing, Team, Counter, Separator, ServiceThree, SectionTitle, Icon, Layout
    },
    data() {
        return {
            isContentVisible: false,
            splitData: {
                image: 'services_target1.png',
                title: 'NetO Network',
                description: `NetO Network Infrastructure with Cyber Security.`,
                list: [
                    '- Safer',
                    '- Faster',
                    '- Lower cost',
                    '- More convenient'
                ],
                btn: {
                    text: 'Contact With Us',
                    link: '/contact'
                }
            },
            counterData: [
                {
                    number: 199,
                    title: 'Happy Clients',
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
                },
                {
                    number: 575,
                    title: 'Employees',
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
                },
                {
                    number: 69,
                    title: 'Useful Programs',
                    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry'
                }
            ],
            teamData: [
                {
                    image: 'teamperson_4',
                    name: 'Jason',
                    designation: 'Chief Technology Officer, CTO',
                    location: 'jason.wan@neto-x.com',
                    description: 'I am the Chief Technology Officer and I will provide you with an ideal architecture solution.'
                },
                {
                    image: 'teamperson_1',
                    name: 'Jennifer',
                    designation: 'Chief Executor Officer, CEO',
                    location: 'jennifer@neto-x.com',
                    description: 'I am the operator and manager of the company. You are welcome to cooperate with us at any time.'
                },
                {
                    image: 'teamperson_3',
                    name: 'James',
                    designation: 'Technical Manager',
                    location: 'jamesyuan@neto-x.com',
                    description: 'If you want to determine a practical and feasible execution plan, my plan will definitely satisfy you.'
                },
                {
                    image: 'teamperson_2',
                    name: 'Sophie',
                    designation: 'Consultant',
                    location: 'rubin@neto-x.com',
                    description: 'I am your business advisor, and you can contact me anytime if you have any business questions.'
                },
            ],
            pricingData: [
                {
                    title: 'Free',
                    currency: '$',
                    price: '29',
                    subtitle: 'USD Per Month',
                    features: [
                        { title: '5 PPC Campaigns' },
                        { title: 'Digital Marketing' },
                        { title: 'Marketing Agency' },
                        { title: 'Seo Friendly' },
                        { title: 'UI/UX designs' },
                    ],
                    btn: {
                        text: 'Purchase Now',
                        link: '#'
                    }
                },
                {
                    title: 'Business',
                    currency: '$',
                    price: '50',
                    subtitle: 'USD Per Month',
                    features: [
                        { title: '5 PPC Campaigns' },
                        { title: 'Keep 100% Royalties' },
                        { title: 'App Development' },
                        { title: 'Seo Friendly' },
                        { title: 'UI/UX designs' },
                    ],
                    btn: {
                        text: 'Purchase Now',
                        link: '#'
                    },
                    isActive: true
                },
                {
                    title: 'Advanced',
                    currency: '$',
                    price: '100',
                    subtitle: 'USD Per Month',
                    features: [
                        { title: '5 PPC Campaigns' },
                        { title: 'SEO Marketing' },
                        { title: 'Marketing Agency' },
                        { title: 'Seo Friendly' },
                        { title: 'Application Manage' },
                    ],
                    btn: {
                        text: 'Purchase Now',
                        link: '#'
                    }
                }
            ],
        }
    },
    methods: {
        toggleContent() {
            this.isContentVisible = !this.isContentVisible;
        },
    }
}
</script>
<style scoped lang="scss">
// .main_content {
//     margin-left: 50px;
// }

// //如果屏幕宽度小于400px，则取消margin-left
// @media (max-width: 400px) {
//     .main_content {
//         margin-left: 0;
//     }
// }

#title_logo {
    color: #FFFFFF;
    /* 白色文字 */
    text-shadow: 0px 0px 15px rgba(255, 255, 255, 0.4);
    
}

li {
    position: relative;
    color: #ffffff;
    text-shadow:
        1px 1px 0px rgba(0, 0, 0, 1),
        -1px -1px 0px rgba(0, 0, 0, 1),
        1px -1px 0px rgba(0, 0, 0, 1),
        -1px 1px 0px rgba(0, 0, 0, 1);
    padding: 0px;
    margin: 0px auto;
    overflow: hidden;
    /* 隐藏滚动条 */
    transition: background-color 0.3s ease;
    /* 平滑过渡效果 */
    display: flex;
    flex-direction: column;
    height: auto; // Change from fixed height to auto
    max-height: 400px; // Add a max-height for scrolling
    overflow-y: auto; // Always show vertical scrollbar if needed
    ul, ol {
        margin-left: 20px;
        li {
            font-size: 16px;
            line-height: 1.6;
            margin-bottom: 10px;
        }
    }
    p{
        font-size: 18px;
        line-height: 2.0;
        //内边距
        padding: 0 20px 0 20px;


    }

}

li:hover {
    overflow-y: scroll;
    /* 显示纵向滚动条 */
    color: black;
    /* 文字颜色改为黑色 */
    text-shadow: none;
    background-color: rgba(89, 89, 89, 0.25);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border: 0px solid rgba(255, 255, 255, 0.18);
    box-shadow: rgba(14, 14, 14, 0.19) 0px 6px 15px 0px;
    -webkit-box-shadow: rgba(14, 14, 14, 0.19) 0px 6px 15px 0px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    color: rgb(128, 128, 128);
}
.toggle-btn {
        margin-left: 10px;
        padding: 5px 10px;
        font-size: 14px;
        background-color: rgba(255, 255, 255, 0.1);
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
</style>