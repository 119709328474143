<template>
    <Layout>
        <!-- Start Contact Area  -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <!-- add NetO's own statement  -->
                <div class="row rn-section-gap" >
                    <div class="col-lg-12">
                        <div class="inner text-center">
                            <span class="subtitle" data-aos="fade-up" data-aos-delay="100">NetO look forward to your
                                contact</span>
                            <h1 class="title theme-gradient display-two" data-aos="fade-up" data-aos-delay="150">
                                <vue-typed-js class="justify-content-center"
                                    :strings="['', 'NetO think', 'NetO want to sa', 'NetO\'s self statement.',]"
                                    :loop="false" :type-speed="80" :start-delay="2000" :back-speed="50">
                                    <b class="is-visible theme-gradient typing">some words...</b>
                                </vue-typed-js>
                            </h1>
                            <!-- <p class="description" data-aos="fade-up" data-aos-delay="200">
                                NetO is considered an open source corporation ，
                                we encourage all members of the community to connect us with your bussiness idea or
                                resource，
                                duration of the cooperation by various individuals and groups can help bring NetO and your
                                idea become a reality.
                            </p> -->
                            <div class="inner">
                                <h5 class="title mb--10"> NetO is considered an open source corporation, we encourage all members of <br>the 
                                    community to connect us with your bussiness idea or resource, duration of the <br>cooperation by
                                    various individuals and groups can help bring NetO and your idea become a reality.
                                </h5>
                            </div>
                            <!-- <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                                        <router-link class="btn-default btn-medium round btn-icon" to="#">
                                            Purchase Now
                                            <Icon name="arrow-right" size="20" icon-class="icon" />
                                        </router-link>
                                        <router-link class="btn-default btn-medium btn-border round btn-icon" to="/contact">
                                            Contact Us
                                            <Icon name="arrow-right" size="20" icon-class="icon" />
                                        </router-link>
                                    </div> -->
                        </div>
                    </div>
                </div>
                <!-- end NetO's own statement -->
                <div class="row rn-section-gap">
                    <div class="col-lg-12 mb--40">
                        <SectionTitle text-align="center" subtitle="Contact Form" title="Our Contact Address Here" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12">
                        <div class="rn-contact-address mt_dec--30">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="headphones" :size="40" />
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">Contact Phone Number</h4>
                                            <p><a href="tel:+13104655210">+1 310 465 5210</a></p>
                                            <!-- <p><a href="tel:+222 222 222 333">+222 222 222 333</a></p> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="mail" :size="40" />
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">Our Email Address</h4>
                                            <p><a href="mailto:jason.wan@neto-x.com">jason.wan@neto-x.com</a></p>
                                            <!-- <p><a href="mailto:example@gmail.com">example@gmail.com</a></p> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="map-pin" :size="40" />
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">Our Location</h4>
                                            <p>Los Angeles, California, USA</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt--40 row--15">
                    <div class="col-lg-5 mt_md--30 mt_sm--30">
                        <GoogleMap />
                    </div>
                    <div class="col-lg-7">
                        <ContactForm />
                    </div>

                </div>
            </div>
        </div>
        <!-- End Contact Area  -->
    </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm.vue";

export default {
    name: 'ContactPage',
    components: { ContactForm, GoogleMap, Icon, SectionTitle, Breadcrumb, Layout }
}
</script>