// src/utils/lazy/lazy.js

export default {
  install(Vue) {
    Vue.directive('lazy', {
      bind(el) {
        console.log("🚀 ~ bind ~ bind:", el.src);

        // 保存图片的原始src
        el._imgSrc = el.src;
        el.src = ''; // 清空src以防止图片在进入视口前加载
      },
      inserted(el) {
        console.log("🚀 ~ inserted ~ inserted:", el.src);

        // 创建IntersectionObserver实例
        el._observer = new IntersectionObserver(([{ isIntersecting }]) => {
          if (isIntersecting) {
            el.src = el._imgSrc; // 设置图片src以开始加载
            el._observer.unobserve(el); // 停止观察
          }
        });
        el._observer.observe(el); // 开始观察
      },
      unbind(el) {
        console.log("🚀 ~ unbind ~ unbind:", el.src);

        // 清理Observer
        if (el._observer) {
          el._observer.unobserve(el);
          el._observer = null;
        }
      }
    });
  }
};
